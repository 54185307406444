import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import UnternehmensFoto from '../components/images/kunden'
function AboutUsPage() {
  return (
    <Layout>
      <SEO
        title="Über uns"
        keywords={[`gummelt partner`, `Immobilienbüro`, `vertrauenswürdig`, `versicherung`, `geldanlage`]}
      />

      <section className="text-center">
        <h1>Über Gummelt Immobilien</h1>

        <p>Das Immobilienb&uuml;ro Gummelt steht f&uuml;r die traditionalen Werte ein:</p>

        <ul className="list-inside list-disc font-bold">
          <li>Sicherheit</li>
          <li>Vertrauensw&uuml;rdigkeit</li>
          <li>Schnelligkeit</li>
          <li>Präzision und</li>
          <li>Verantwortungsbewusstsein</li>
        </ul>
        <p>Das Thema Persönlichkeit liegt uns sehr am
        Herzen. Ihre Immoblie und Ihre Zeit ist für uns
        ein wertvolles Gut. Wir behandeln es
wertschätzend und diskret.</p>
        <p>Die Kunst liegt unserer Meinung nach darin,
        die Immobilie nach aktueller Marktlage
        einzuschätzen und für Sie komfortabel zu
vermitteln.</p>
        <p className="font-bold">Wir sind gerne für Sie da!</p>
        <p align="center"><UnternehmensFoto></UnternehmensFoto></p>
      </section>
    </Layout>
  );
}

export default AboutUsPage;
